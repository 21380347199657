@import "../../../styles/roots";

.Footer {
  padding-top: 5rem;
  padding-bottom: 2rem;
  background: $primary-dark;
  border-top: 3px solid $secondary;
  position: relative;

  .social-link {
    display: block;
    height: 4rem;
    width: 4rem;
    line-height: 4.3rem;
    font-size: 1.5rem;
    background-color: $secondary;
    -webkit-transition: background-color 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out;
    -webkit-box-shadow: 0 3px 3px 0 $primary;
    box-shadow: 0 3px 3px 0 $primary;
  }

  .social-link:hover {
    background-color: $secondary-dark;
    text-decoration: none;
  }

  .up-arrow {
    position: absolute;
    top: -37px;
    margin-left: -42px;
    background-color: $highlight;
    color: $secondary-color;
    font-size: 40px;
    padding: 7px 27px;
    border-radius: 50%;
    cursor: pointer;
  }

  .imprint-button {
    color: $secondary;
    background-color: transparent;
    border: 0;
    box-shadow: unset;
    outline: 0;
  }
}
