$highlight: #E31B6D;

$primary-light: #4d515e;
$primary: #252934;
$primary-dark: #1B242F;
$primary-color: #FFFFFF;

$secondary-light: #62f5fc;
$secondary: #04C2C9;
$secondary-dark: #009198;
$secondary-color: #FFFFFF;

$theme-colors: (
        "primary": #E31B6D,
);
