.LandingPage {
  height: 100vh;
  display: flex;

  .content {
    margin: auto 0;
    width: 100%;
    display: block;
    text-align: center;

    .logo-container {
      margin-bottom: 40px;
    }

    .chains-container {
      .chain-row {
        display: block;
      }
    }
  }
}
