@import "../../../styles/roots";

.Navigation {
  position: sticky;
  top: 0;
  z-index: 50;
  height: 53px;
  background: $primary;

  nav {
    background-color: $primary-dark;
    border-bottom: 3px solid $secondary;
    z-index: 5;
    height: 53px;
  }

  nav.fixed {
    top: 0;
    bottom: inherit;
    left: 0;
    position: fixed;
    width: 100%;
  }

  @keyframes popDown {
    0% {
      transform: translateY(-100px);
    }
  }

  nav.fixed.desk {
    animation: popDown .5s;
  }

  nav ul {
    transition: height .3s ease-out;
  }

  nav ul a {
    color: white;
    line-height: 47px;
    padding: 0 17px !important;

    font-size: 12pt;
    cursor: pointer;
    transition: color .5s;
    text-transform: uppercase;
  }

  nav ul li:last-child a {
    padding-right: 0 !important;
  }

  nav ul a:hover {
    color: $highlight;
  }

  nav a.active {
    color: $highlight;
  }

  @media (min-width: 576px) {
    .navbar-toggler {
      display: none;
    }
  }

  @media (max-width: 576px) {
    position: fixed !important;
    left: 0;
    right: 0;

    .navbar-toggler {
      z-index: 10;
      position: absolute;
      right: 10px;
      font-size: 12px;
      padding: 13px;
      top: 5px;
      color: $secondary-color;
      background-color: $secondary;
    }

    nav ul {
      display: block !important;
      height: 0;
      overflow: hidden;

      position: absolute;
      top: 53px;
      left: 0;
      right: 0;
      background-color: $secondary;
    }

    nav ul.visible {
      height: 240px;
      animation: height 0.4s;
    }

    nav ul li {
      display: block;
    }
  }
}
