@import "../../../styles/roots";

.TimelineSection {
  .border-right, .border {
    border-color: $secondary !important;
  }

  .timeline-year {
    border-radius: 50%;
    line-height: 60px;
    width:60px;
    text-align: center;
    padding:0;
    font-weight: normal !important;
  }

  .card {
    background-color: $primary-dark;
    text-align: left;
    color: $primary-color;
  }

   .card .float-right {
    color: #6c757d;
  }

   .col-sm-1 {
    min-width: 73px;
  }
}
