@import "../../../styles/roots";

.PortfolioSection {
  .portfolio-gallery {
    font-size: 0;
  }

  .portfolio-item {
    width: 364px;
    max-width: 100%;
    height: 280px;

    font-size: 13pt;
    text-align: center;

    cursor: pointer;
    background-color: $secondary;

    display: inline-block;
    position: relative;
  }

  .portfolio-item-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-size: cover;
    background-position: 50% 50%;
    background-color: $secondary;
    background-repeat: no-repeat;
  }

  .portfolio-item-text {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    text-align: center;
    background-color: $secondary;

    opacity: 0;
  }

  .portfolio-item:hover .portfolio-item-text {
    opacity: 1;
    transition: opacity .5s;
  }

  .portfolio-item-title {
    margin: 50px 0 10px 0;

    display: block;
    font-weight: 700;
    font-size: 16pt;
    color: $secondary-color;
  }

  .portfolio-item-caption {
    margin: 0 0 50px 0;

    display: block;
    color: $highlight;
  }

  .portfolio-item-button {
    border: 3px solid $highlight;
    color: $secondary-color;
    background: transparent !important;
    padding: 10px 30px;
  }

  .portfolio-item-button:hover {
    background-color: $highlight !important;
    transition: background-color .5s;
  }

  .gallery-screenshots {
    width: 100%;
    min-height: 200px;
    background-color: $primary;
  }

  .gallery-body {
    padding: 20px;
  }

  .gallery-title {
    display: block;
    color: $highlight;
    font-size: 1.7rem;
    font-weight: bold;
  }

  .gallery-caption {
    display: block;
    color: $primary-color;
    font-size: 1.1rem;
    font-weight: bold;

    padding: 0 0 15px 0;
    border-bottom: 1px solid $secondary;
  }

  .gallery-description {
    display: block;
    margin: 20px 0 30px 0;
    color: $primary-color;
  }

  .gallery-link {
    display: inline-block;
    border: 2px solid $secondary;
    color: $secondary;
    padding: 8px 20px;
    margin: 0;
    background-color: transparent;
    outline: 0;
    box-shadow: unset;
    font-size: 15px;
  }

  .gallery-link:hover {
    background-color: $secondary;
    transition: background-color 0.5s;
    color: $primary;
  }
}
