@import "../../../styles/roots";

.AboutSection {
  .personal-photo {
    border-radius: 50%;
    width:350px;
  }

  .service-icon {
    background-color: $secondary;
    color: $secondary-color;
    height: 7rem;
    width: 7rem;
    display: block;
    line-height: 7.5rem;
    font-size: 2.25rem;
    box-shadow: 0 3px 3px 0 $primary;
  }

  .skill {
    display: block;
    margin: 15px 0;
    font-size: 0;

    .label {
      background-color: $secondary;
      width: 130px;

      display: inline-block;

      padding: 0;
      margin: 0;
      line-height: 30px;
      font-size: 0.75rem;
      color: $secondary-color;

      float: left;

      border-radius: 5px 0 0 5px;
    }

    .progress {
      background-color: $primary-light;
      display: inline-block;
      width: calc(100% - 135px);
      border: 0;
      margin: 0 0 0 -5px;
      height: 30px;
      border-radius: 0 5px 5px 0;
    }

    .progress-bar {
      background-color: $secondary-dark;
      line-height: 30px;
      font-size: 0.75rem;
      color: $secondary-color;
      border-radius: 0 5px 5px 0;
      height: 100%;

      transition: 1s 0.8s width ease-in-out;
    }
  }
}
