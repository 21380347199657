@import "../../../styles/roots";

.ContactSection {
  .contact-form {
    max-width: 600px;
    margin: 0 auto;

    .form-control {
      margin: 5px;
    }

    textarea {
      min-height: 200px;
    }

    .form-control {
      color: $primary-color;
      background-color: $primary;
      outline: 0;
      border: 1px solid $primary-dark;
    }
  }

  .contact-alert {
    border: 0;
    color: white;
  }
}
