@import "./roots.scss";

@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/animate.css/animate.css";
@import url('https://fonts.googleapis.com/css?family=Raleway:400,600,700&display=swap');

// colors
.bg-primary {
  background-color: $primary !important;
}

.bg-primary-light {
  background-color: $primary-light !important;
}

.bg-primary-dark {
  background-color: $primary-dark !important;
}

.bg-secondary {
  background-color: $secondary !important;
}

.bg-secondary-dark {
  background-color: $secondary-dark !important;
}

.bg-secondary-light {
  background-color: $secondary-light !important;
}

.text-primary {
  color: $primary-color !important;
}

.text-secondary {
  color: $secondary-color !important;
}

.text-highlight {
  color: $highlight !important;
}

// standard classes
h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

a {
  color: $secondary;
  text-decoration: none;
}

a:hover, a:focus, a:active {
  color: $secondary-dark;
  text-decoration: none;
}

.btn-xl {
  padding: 1.25rem 2.5rem;
}

.btn {
  outline: 0;
}

.content-section {
  padding-top: 5.5rem;
  padding-bottom: 5.5rem;
}

.content-section-heading h2 {
  font-size: 3rem;
}

.content-section-heading h3 {
  font-size: 1rem;
  text-transform: uppercase;
}

.btn-primary {
  background-color: $primary !important;
  border-color: $primary !important;
  color: $primary-color !important;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  background-color: $primary !important;
  border-color: $primary !important;
}

.btn-secondary {
  background-color: $secondary !important;
  border-color: $secondary !important;
  color: $secondary-color !important;
}

.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active {
  background-color: $secondary !important;
  border-color: $secondary !important;
}

.btn {
  box-shadow: 0 3px 3px 0 $primary;
  font-weight: 700;
}

.modal-content {
  background-color: $primary;
  border: 0;
  border-radius: 0;
  color: $primary-color;
}

.modal-header {
  border-top: 3px solid $secondary;
  color: $secondary;
  border-radius: 0;
  border-bottom: 1px dotted $secondary;
}

.modal-header .close {
  color: $secondary;
}

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 1000px;
  }
}


/* DESIGN */

body {
  width: 100%;
  height: 100%;
  font-family: 'Raleway', Arial, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background: $primary-dark;
}
