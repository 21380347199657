@import "../../../styles/roots";

.Masthead {
  min-height: 30rem;
  position: relative;
  display: table;
  width: 100%;
  height: auto;
  padding-top: 8rem;
  padding-bottom: 8rem;
  background: url('./header.jpg') $primary-dark no-repeat center bottom;
  background-size: cover;

  h1 {
    font-size: 4rem;
    margin: 0;
    padding: 0;
  }

  .main-text {
    color: $secondary-color;
    font-size: 3rem;
    text-shadow: 3px 3px $primary;
    background-color: rgba(0, 0, 0, 0.55);
    padding: 20px;
    display: inline-block;
    //box-shadow: 0 0 10px 0 #000;
  }
}

@media (min-width: 992px) {
  .Masthead {
    height: 100vh;
  }

  .Masthead h1 {
    font-size: 5.5rem;
  }
}
